import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { ThemeProvider } from 'styled-components';
import {
    isEmailVerifiedSignalEnabled,
    isCsmCustomPagesEnabled,
    isEditLayoutEnabledForCSM,
    isCustomPagesUiEnabled,
} from 'feature-flags';
import { createMemoryHistory } from 'history';
import type { Route } from 'react-resource-router';
import { RouteComponent, Router } from 'react-resource-router';
import { getViewHomePageRoute, getEditHomePageRoute } from 'routes/advanced-help-center';
import { aiAnswerRoute } from 'routes/aianswer';
import { approvalsRoute } from 'routes/approvals';
import { consentFormRoute } from 'routes/consent-form';
import { csmContactUsRoute } from 'routes/csm-contact-us';
import { getViewCustomPageRoute, getEditCustomPageRoute } from 'routes/custom-pages';
import { addTopicRoute, editTopicRoute } from 'routes/edit-topic';
import { embedCreateRequestRoute, embedKBArticleRoute, embedSinglePortalRoute } from 'routes/embed-smartlinks';
import { getHelpCenterRoute } from 'routes/help-center';
import {
    viewAllSearchResultsRoute,
    viewArticleRoute,
    viewCrossSiteArticleRoute,
    viewKbPageRoute,
    viewPortalAllSearchResultsRoute,
    viewPortalArticleRoute,
    viewCrossSitePortalArticleRoute,
    viewPortalCategoryArticleRoute,
    viewPortalKnowledgeCategoryRoute,
} from 'routes/kb';
import { loginRoute, portalLoginRoute } from 'routes/login';
import { portalAccessForbiddenRoute } from 'routes/portal-access-forbidden';
import { getPortalHomeRoutes } from 'routes/portal-home';
import { requestApprovalRoute } from 'routes/request-approval';
import { requestDetailsRoute } from 'routes/request-details';
import { requestsListRoute } from 'routes/requests';

import {
    portalCompleteSignupRoute,
    portalInvitedSignupRoute,
    portalResetPasswordRoute,
    portalSignupRoute,
    userCheckMailRoute,
    userCompleteSignupRoute,
    userResetPasswordRoute,
    userSignupRoute,
} from 'routes/signup';
import { getViewTopicRoute } from 'routes/topics';
import { userVerifyEmailRoute } from 'routes/verify-email';
import type { State } from 'state';
import { getHeadless } from 'state/selectors/env';
import { getHelpCenterTitleColor, getThemeColor } from 'state/selectors/help-center';
import { getUserState } from 'state/selectors/user';
import { lightenColor, readableTextColor } from 'view/styles/colors';
import { GlobalStyled } from 'view/styles/global';
import {
    isFlexiHCEnabledInDefaultHC,
    isAdvancedHelpCenter,
    isJcsOnlyRestrictedRoute,
} from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv, getIsHCCustomizationEnabled } from '@atlassian/help-center-common-util/env';
import { getBaseName, history } from '@atlassian/help-center-common-util/history';
import { UFOWatcher } from '@atlassian/help-center-common-util/ufo-watcher';
import { resourcesPlugin } from '../resource-plugin';
import {
    anonymousRequestSuccessRoute,
    notFoundErrorRoute,
    portalReorderRoute,
    getRequestCreateRoute,
    requestFeedbackRoute,
    unsubscribeRoute,
    userProfileRoute,
} from '../routes';

export const getRoutesOld = (): Route[] => {
    const isAdvancedOrFlexiHCEnabled = isAdvancedHelpCenter() || isFlexiHCEnabledInDefaultHC();

    return [
        ...(isAdvancedOrFlexiHCEnabled ? [getViewHomePageRoute()] : []),
        ...(isAdvancedOrFlexiHCEnabled && isEditLayoutEnabledForCSM() ? [getEditHomePageRoute()] : []),
        ...(isEmailVerifiedSignalEnabled() ? [userVerifyEmailRoute] : []),
        aiAnswerRoute,
        consentFormRoute,
        loginRoute,
        userResetPasswordRoute,
        userSignupRoute,
        userCompleteSignupRoute,
        userCheckMailRoute,
        portalAccessForbiddenRoute,
        portalLoginRoute,
        portalSignupRoute,
        portalResetPasswordRoute,
        portalCompleteSignupRoute,
        portalInvitedSignupRoute,
        requestFeedbackRoute,
        unsubscribeRoute,
        getHelpCenterRoute(),
        requestDetailsRoute,
        viewKbPageRoute,
        viewAllSearchResultsRoute,
        viewPortalAllSearchResultsRoute,
        viewPortalCategoryArticleRoute,
        viewPortalArticleRoute,
        viewCrossSitePortalArticleRoute,
        viewArticleRoute,
        viewCrossSiteArticleRoute,
        requestsListRoute,
        approvalsRoute,
        requestApprovalRoute,
        userProfileRoute,
        viewPortalKnowledgeCategoryRoute,
        anonymousRequestSuccessRoute,
        getRequestCreateRoute(),
        getPortalHomeRoutes(),
        embedCreateRequestRoute,
        embedSinglePortalRoute,
        embedKBArticleRoute,
        portalReorderRoute(true),
        editTopicRoute,
        addTopicRoute,
        getViewTopicRoute(),
        ...(isEditLayoutEnabledForCSM() ? [csmContactUsRoute] : []),
        ...(isCsmCustomPagesEnabled() && isCustomPagesUiEnabled() && getIsHCCustomizationEnabled()
            ? [getEditCustomPageRoute(), getViewCustomPageRoute()]
            : []),
        notFoundErrorRoute,
    ];
};

export interface Props {
    headerless: boolean;
    themeColor: string;
    titleColor: string;
    user?: {
        externalCustomer: boolean;
    };
}

export const CSMResourceRouterOld = (props: Props) => {
    const { themeColor, titleColor } = props;
    const readableTextColorFromTheme = readableTextColor(themeColor);
    const lightColorFromTheme = lightenColor(themeColor);
    const routes = useMemo(() => {
        return getRoutesOld();
    }, []);
    // eslint-disable-next-line @atlassian/ssr-linting/no-dom-globals-in-react-fc
    const location = window.location.pathname + window.location.search;

    const theme = {
        titleColor,
        readableTextColor: readableTextColorFromTheme,
        color: themeColor,
        lightColor: lightColorFromTheme,
    };

    if (__SERVER__) {
        return (
            <ThemeProvider theme={theme}>
                <Router
                    routes={routes}
                    isGlobal={false}
                    basePath={getBaseName()}
                    history={createMemoryHistory({ initialEntries: [location] })}
                    plugins={[resourcesPlugin]}
                >
                    <GlobalStyled />
                    <RouteComponent />
                </Router>
            </ThemeProvider>
        );
    }

    //isGlobal=false, to avoid the router conflicting with the sidebar's router.
    return (
        <ThemeProvider theme={theme}>
            <Router routes={routes} history={history} isGlobal={false} plugins={[resourcesPlugin]}>
                <GlobalStyled />
                {!__SERVER__ ? <UFOWatcher /> : null}
                <RouteComponent />
            </Router>
        </ThemeProvider>
    );
};

const selectorOld = createSelector(
    getHeadless,
    getThemeColor,
    getHelpCenterTitleColor,
    (headerless, themeColor, titleColor) => ({
        headerless,
        themeColor,
        titleColor,
    })
);

export const getRoutes = ({ externalCustomer }: { externalCustomer: boolean | undefined }): Route[] => {
    // Routes that are restricted to non external users when
    // base path is /servicedesk/customer and customerAccountAvailabilty is JCS_ONLY
    const isAdvancedOrFlexiHCEnabled = isAdvancedHelpCenter() || isFlexiHCEnabledInDefaultHC();
    const restrictedRoutes = [
        ...(isAdvancedOrFlexiHCEnabled ? [getViewHomePageRoute()] : []),
        ...(isAdvancedOrFlexiHCEnabled && isEditLayoutEnabledForCSM() ? [getEditHomePageRoute()] : []),
        ...(isEmailVerifiedSignalEnabled() ? [userVerifyEmailRoute] : []),
        aiAnswerRoute,
        consentFormRoute,
        loginRoute,
        userResetPasswordRoute,
        userSignupRoute,
        userCompleteSignupRoute,
        userCheckMailRoute,
        portalAccessForbiddenRoute,
        portalLoginRoute,
        portalSignupRoute,
        portalResetPasswordRoute,
        portalCompleteSignupRoute,
        portalInvitedSignupRoute,
        requestFeedbackRoute,
        unsubscribeRoute,
        getHelpCenterRoute(),
        requestDetailsRoute,
        viewKbPageRoute,
        viewAllSearchResultsRoute,
        viewPortalAllSearchResultsRoute,
        viewPortalCategoryArticleRoute,
        viewPortalArticleRoute,
        viewCrossSitePortalArticleRoute,
        viewArticleRoute,
        viewCrossSiteArticleRoute,
        requestsListRoute,
        approvalsRoute,
        requestApprovalRoute,
        userProfileRoute,
        embedCreateRequestRoute,
        embedSinglePortalRoute,
        embedKBArticleRoute,
        ...(isEditLayoutEnabledForCSM()
            ? [csmContactUsRoute]
            : [
                  portalReorderRoute(true),
                  editTopicRoute,
                  addTopicRoute,
                  getViewTopicRoute(),
                  viewPortalKnowledgeCategoryRoute,
                  anonymousRequestSuccessRoute,
                  getRequestCreateRoute(),
                  getPortalHomeRoutes(),
              ]),
        ...(isCsmCustomPagesEnabled() && isCustomPagesUiEnabled() && getIsHCCustomizationEnabled()
            ? [getEditCustomPageRoute(), getViewCustomPageRoute()]
            : []),
    ];

    return [
        ...(isJcsOnlyRestrictedRoute(getEnv().customerAccountAvailabilty) && externalCustomer ? [] : restrictedRoutes),
        notFoundErrorRoute,
    ];
};

export const CSMResourceRouter = (props: Props) => {
    const { themeColor, titleColor, user } = props;
    const readableTextColorFromTheme = readableTextColor(themeColor);
    const lightColorFromTheme = lightenColor(themeColor);
    const routes = useMemo(() => {
        return getRoutes({ externalCustomer: user?.externalCustomer });
    }, [user]);
    // eslint-disable-next-line @atlassian/ssr-linting/no-dom-globals-in-react-fc
    const location = window.location.pathname + window.location.search;

    const theme = {
        titleColor,
        readableTextColor: readableTextColorFromTheme,
        color: themeColor,
        lightColor: lightColorFromTheme,
    };

    if (__SERVER__) {
        return (
            <ThemeProvider theme={theme}>
                <Router
                    routes={routes}
                    isGlobal={false}
                    basePath={getBaseName()}
                    history={createMemoryHistory({ initialEntries: [location] })}
                    plugins={[resourcesPlugin]}
                >
                    <GlobalStyled />
                    <RouteComponent />
                </Router>
            </ThemeProvider>
        );
    }

    //isGlobal=false, to avoid the router conflicting with the sidebar's router.
    return (
        <ThemeProvider theme={theme}>
            <Router routes={routes} history={history} isGlobal={false} plugins={[resourcesPlugin]}>
                <GlobalStyled />
                {!__SERVER__ ? <UFOWatcher /> : null}
                <RouteComponent />
            </Router>
        </ThemeProvider>
    );
};

const selector = createSelector(
    getHeadless,
    getThemeColor,
    getHelpCenterTitleColor,
    (state: State) => getUserState(state),
    (headerless, themeColor, titleColor, userState) => ({
        headerless,
        themeColor,
        titleColor,
        user: {
            externalCustomer: userState.externalCustomer,
        },
    })
);

export const CSMResourceRouterNew = connect(selector)(CSMResourceRouter);

export default connect(selectorOld)(CSMResourceRouterOld);
